export const email_template = {
  create_new_rfq: "<div><strong>Dear {{ labs }} ,<br></strong><br></div><div>We would like a quote for RFQ number: <strong>{{ rfq_id }}</strong> with name:<strong> {{ service_name }}</strong>.</div><div><br>{{ description }}<br><br></div><blockquote><strong>Delivery date:</strong> {{ delivery_date }}<br><strong>Expiry date: </strong>{{ expires_on }}<strong><br>Examination count:</strong> {{ examination_count }}</blockquote><div><br></div><div>{{ files }}<br><br></div><div>Please contact us if you have any questions or would like further information.</div><div><br><strong>Send from </strong><strong><em>{{ sender }}</em></strong><strong>, </strong></div><blockquote><ul><li>Email : {{ sender_email }}</li><li>Phone : {{ sender_phone_number }}</li></ul></blockquote>",
  create_rfi_for_cold_vendors: "<div><strong>Dear {{ labs }} ,<br></strong><br></div><div>We would like a quote RFI for Cold Vendor number: <strong>{{ rfq_id }}</strong> with name:<strong> {{ service_name }}</strong>.</div><div><br>{{ description }}<br><br></div><blockquote><strong>Delivery date:</strong> {{ delivery_date }}<br><strong>Expiry date: </strong>{{ expires_on }}<strong><br>Examination count:</strong> {{ examination_count }}</blockquote><div><br></div><div>{{ files }}<br><br></div><div>Please contact us if you have any questions or would like further information.</div><div><br><strong>Send from </strong><strong><em>{{ sender }}</em></strong><strong>, </strong></div><blockquote><ul><li>Email : {{ sender_email }}</li><li>Phone : {{ sender_phone_number }}</li></ul></blockquote>",
  complementary_information: "<div><strong>Dear {{ labs }} ,<br><br></strong>We would inform you there are some complementary information added to the RFQ, please read it, if necessary send a new Offer for <strong>{{ service_name }}</strong>. .</div><div><br>{{ description }}<br><br></div><blockquote><strong>Expiry date: </strong>{{ expires_on }}</blockquote><div><br></div><div>{{ files }}<br><br></div><div>Please contact us if you have any questions or would like further information.</div><div><br><strong>Send from </strong><strong><em>{{ sender }}</em></strong><strong>, </strong></div><blockquote><ul><li>Email : {{ sender_email }}</li><li>Phone : {{ sender_phone_number }}</li></ul></blockquote>",
  complementary_question: "<div><strong>Dear {{ labs }} ,<br><br></strong>We would inform you there are some complementary question added to the RFQ, please read it, if necessary send a new Offer for <strong>{{ service_name }}</strong>. .</div><div><br>{{ description }}<br><br></div><blockquote><strong>Expiry date: </strong>{{ expires_on }}</blockquote><div><br></div><div>{{ files }}<br><br></div><div>Please contact us if you have any questions or would like further information.</div><div><br><strong>Send from </strong><strong><em>{{ sender }}</em></strong><strong>, </strong></div><blockquote><ul><li>Email : {{ sender_email }}</li><li>Phone : {{ sender_phone_number }}</li></ul></blockquote>",
  offer_shortlist: "<div><strong>Dear {{ labs }} ,<br><br></strong>We would inform you that a shortlist has been added to the offer, please read it.<br>If necessary, please send a new Offer for <strong>{{ offer_title }}</strong>.</div><div><br>{{ description }}<br><br></div><div><br></div><div>{{ files }}<br><br></div><div>Please contact us if you have any questions or would like further information.</div><div><br>{{ services_information }}</div><div><br><strong>Send from </strong><strong><em>{{ sender }}</em></strong><strong>, </strong></div><blockquote><ul><li>Email : {{ sender_email }}</li><li>Phone : {{ sender_phone_number }}</li></ul></blockquote>",
  offer_reject: "<div><strong>Dear {{ labs }} ,<br><br></strong>We would like inform you that your offer has been rejected, please read it.<br>If necessary send a new Offer for <strong>{{ offer_title }}</strong>. .</div><div><br>{{ description }}<br><br></div><div><br></div><div>{{ files }}<br><br></div><div>Please contact us if you have any questions or would like further information.</div><div><br>{{ services_information }}</div><div><br><strong>Send from </strong><strong><em>{{ sender }}</em></strong><strong>, </strong></div><blockquote><ul><li>Email : {{ sender_email }}</li><li>Phone : {{ sender_phone_number }}</li></ul></blockquote>",
  create_company: "<h1>New Pending Organization</h1><div>There's a new organization awaiting your review. </div><div>Create by: {{ create_by }}<br>Company name: {{ name }} (#{{ company_id }})<br>Company website: {{ website }}<br>Company function: {{ company_function }}<br><br>Address: {{ headquarters_street_address }}  {{ headquarters_house_number }}  {{ headquarters_city }} {{ headquarters_country }}  {{ headquarters_state }}<br><br></div><div><br></div>",
  change_company: "<div>Hello {{ company_created_by_user_name }},<br><br></div><div>You have to adjust some of your company details so we can approve your company <strong>{{ company_name }} ({{ company_id }})<br></strong><br></div><pre>{{ message }}</pre><div><br></div>",
  change_service: "<div>Hello {{ user_own_service }}<br><br></div><div>You need to make some changes to your service <strong>{{ service_name }} ({{ service_id }}) </strong>before we can approve it on Labs.</div><div><br></div><pre>{{ message }}</pre>",
  approve_company: "<div>Hello <strong>{{ company_created_by_user_name }}</strong>,<br><br></div><div>Your company <strong>{{ company_name }}</strong> ({{ company_id }}) is verified. You're ready to get started with Labs.<br><br></div><div>Grow your business with confidence!</div><pre>{{ message }}</pre><div><br></div>",
  approve_service: "<div>Hello {{ user_own_service }},<br><br></div><div>Your service <strong>{{ service_name }}</strong> (#{{ service_id }}) is approved and now it is available to customers on Labs.<br><br></div><div>Grow your business with confidence!<br><br></div><div>Would you like to add another service?</div><blockquote>{{ message }}</blockquote>",
  cancel_service:"<div>Hello {{ user_own_service }},<br><br></div><div>Your service <strong>{{ service_name }}</strong> (#{{ service_id }}) was not deemed suitable for Labs and has been cancelled.</div><blockquote>{{ message }}</blockquote><div><br></div>",
  modify_company: "<div>There's a organization awaiting your review.<br><br></div><div>Company name: <strong>{{ company_name }} ({{ company_id }})</strong></div><div><br></div><blockquote>{{ message }}</blockquote><div><br></div>",
  statistics_company: "<div>There's a organization awaiting your review.<br><br></div><div>Company name: <strong>{{ company_name }} ({{ company_id }})</strong></div><div><br></div><blockquote>{{ message }}</blockquote><div><br></div>",
  add_new_members: "<div><strong>Hello {{ _surname }} ,<br></strong><br></div><div>You've been invited to join - <strong> {{ name }}</strong></div><div><br>as a {{ role }} on Labs. Set your password to complete setting up your account. <br><br></div><div><br></div><div>Please contact us if you have any questions or would like further information.</div><div><br><strong> Send from </strong><strong><em>{{ sender }}</em></strong><strong>, </strong></div><blockquote><ul><li>Email : {{ sender_email }}</li></ul></blockquote>",
  existing_user_invite_notification: "<div><strong>Hello {{ _surname }} ,<br></strong><br></div><div>You've been added to <strong> {{ name }}</strong></div><div><br>as a {{ role }} on Labs. <br><br></div>",
  sysop_invited_users: "<div>Hello {{ recipient_polite_name }},<br><br>The system operator has invited the below users to join your company: {{ company_name }}<br><br></div><blockquote>{{ list_user }}</blockquote>",
  request_decline: "<div>Dear<strong> {{ company }}</strong>,<br>Your offering request to <strong>{{ labs }}</strong> <br>with service name:  <strong>{{ service_name }}</strong> id: <strong> {{ service_id }} <br></strong>for Request<strong> {{ request_id }}</strong> has been declined.<br><br></div><h1><em>Reason :</em> {{ reason }}</h1>",
  calibration_create: "<h1><strong>Dear {{ contact_name }}, </strong></h1><div><br></div><div>Thank you for sending us your quote request for calibration. Our colleagues will get in touch with you in one business day. <br><br></div><div>Best regards, </div><div><br><em>Customer support team, <br></em><strong>KÖR Group </strong></div>",
}
